import React from "react";
import RedirectorFileButton from "./Buttons/RedirectorFileButton";
import FileButton from "./Buttons/FileButton";
import isList from "./constants/IsList";
import contrast from "./constants/Contrast";
import Drag from "./Drag";

function Youtube() {
  document.title = "Youtube";

  var files = [
    <FileButton label="About My Channel.txt" icon="file-alt" />,
    <RedirectorFileButton
      label="My Channel.mp4"
      link="https://www.youtube.com/channel/UCTz21CTFHqFS9_fL9mMQN0w"
      icon="youtube"
    />,
    <RedirectorFileButton
      label="My Latest Beat.mov"
      link="https://tools.tastethecode.com/youtube-latest/r/recent-video"
      icon="file-video"
    />,
    <RedirectorFileButton
      label="Close to You.mov"
      link="https://www.youtube.com/watch?v=wXqoaanmU1M"
      icon="file-video"
    />,
    <RedirectorFileButton
      label="MOBBIN.mov"
      link="https://www.youtube.com/watch?v=_9N3_x5pn48"
      icon="file-video"
    />,
    <RedirectorFileButton
      label="WILD WEST.mov"
      link="https://www.youtube.com/watch?v=pqjGgV5g36U"
      icon="file-video"
    />,
  ];

  return (
    <div className="page">
      <h1 style={contrast}>Youtube</h1>
      <div className={isList ? "list-view" : "folders"}>
        <Drag files={files} />
      </div>
    </div>
  );
}

export default Youtube;
