import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import isList from "./constants/IsList";

function Drag(props) {
  const { files } = props;

  const [filesState, setFilesState] = useState(files);

  const draggableFiles = filesState.map((file, index) => (
    <Draggable
      draggableId={`draggable-${index}`}
      key={`draggable-${index}`}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {file}
        </div>
      )}
    </Draggable>
  ));

  const dragEnd = (result) => {
    const fileItems = [...filesState];
    const [orderedFiles] = fileItems.splice(result.source.index, 1);
    fileItems.splice(result.destination.index, 0, orderedFiles);
    setFilesState(fileItems);
  };

  return isList ? (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="draggable-files" direction="vertical" type="row">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {draggableFiles}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    files.map((file) => file)
  );
}

export default Drag;
