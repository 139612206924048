import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import AudioBar from "./AudioBar";
import isList from "./constants/IsList";

const barSpeed = 1000;

function AudioPlayer(props) {
  const { time, isPlaying, file } = props;
  const [progress, setProgress] = useState(0);

  const [pause, setPause] = useState(false);

  let duration = `${parseInt(time / 60)}:${String(time % 60).padStart(2, "0")}`;

  const audio = useRef(new Audio(file));

  function togglePause(e) {
    e.preventDefault();
    setPause(!pause);
  }

  useEffect(() => {
    console.log("Double clicked: " + isPlaying);
    setPause(!isPlaying);
    if (!isPlaying) {
      audio.current.pause();
    } else {
      audio.current.play();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (pause) {
      audio.current.pause();
    } else {
      audio.current.play();
    }
  }, [pause]);

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log(progress);
      setProgress(audio.current.currentTime);
    }, barSpeed);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <div className={isList ? "audio-player" : ""}>
      {isPlaying && (
        <div className="audio-bar">
          <span>
            <AudioBar
              isPlaying={!pause}
              curTime={progress}
              duration={audio.current.duration}
            />
            <Button variant="light" size="sm" onClick={(e) => togglePause(e)}>
              <FontAwesomeIcon icon={!pause ? "pause" : "play"} />
            </Button>
            {duration}
          </span>
        </div>
      )}
    </div>
  );
}

export default AudioPlayer;
