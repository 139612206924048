import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import YearbookPhoto from "../images/yearbook photo.jpg";
import { mobile } from "./DetectMobile";

function MeetDS() {
  const [showResume, setShowResume] = useState(false);
  const [gpa, setGpa] = useState(0.0);

  document.title = "Meet DS";

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const resume = (
    <Modal show={showResume} onHide={() => setShowResume(false)} size="lg">
      <Modal.Header>
        <Modal.Title>
          <Container>
            <Row>
              <Col>
                <FontAwesomeIcon icon="file" />
              </Col>
              <Col xs>
                <span className="center">Resume</span>
              </Col>
            </Row>
          </Container>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="resume">
          <iframe
            src="https://drive.google.com/file/d/1xwhCGSWENh5urbHAT-Voa4Htcg3GQV4q/preview"
            allow="autoplay"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <div>
      {resume}

      <div className="meet">
        <img className="yearbook-photo" src={YearbookPhoto} alt="Headshot" />
        <div className="left">
          <h2>
            Name: <b>Daniel Shi</b>
          </h2>
          <h2>
            Age: <b>{getAge("05/16/2002")}</b>
          </h2>
          <h2>
            <a
              href={""}
              style={{ color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                setShowResume(true);
              }}
            >
              Resume
            </a>
          </h2>
        </div>

        {!mobile && <h2 className="misc">Location: California</h2>}
        <div className="right">
          <h2>
            Occupation: <b>University Student</b>
          </h2>
          <h2>
            School: <b>Purdue University</b>
          </h2>
          {!mobile && (
            <>
              <h2 className="misc">
                Degree: <b>Bachelor of Science</b>
              </h2>
              <h2 className="misc">
                GPA: <b>3.62</b>/4
              </h2>
              <h2 className="misc">
                Graduation Year: <b>2023</b>
              </h2>
            </>
          )}

          <h2>
            Major: <b>Computer Science</b>
          </h2>
          {!mobile && (
            <h2 className="misc">
              Career Path: <b>Software Engineering</b>
            </h2>
          )}
        </div>
        {!mobile && (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
              <h2 className="misc">Hair Color: Dark Brown</h2>
              <h2 className="misc">Eye Color: Brown</h2>
              <h2 className="misc">
                Height: <strike>X'X"</strike>
              </h2>
              <h2 className="misc">
                Weight: <strike>1XX lbs</strike>
              </h2>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MeetDS;
