import React from "react";
import Hobby from "./Hobby";

const hobbies = [
  {
    name: "Basketball",
    icon: "basketball-ball",
    content:
      "Basketball is my favorite sport, without a doubt. I love to shoot around in my backyard and whenever I'm with friends, you can always find us hooping at the nearest court.",
  },
  {
    name: "Swimming",
    icon: "swimmer",
    content:
      "I swam competitively as a child for a few years and also swam for my high school team for three years.",
  },
  {
    name: "Music",
    icon: "music",
    content: (
      <>
        <>
          I like to both listen to and create music. If you want to see some of
          the songs/beats that I've produced, click{" "}
          <a href="/home/projects/music">here</a>. Some of favorite genres of
          music include rap, k-pop, k-rnb, EDM, and anime soundtracks. Here's
          what I've been listening to as of late:
        </>
        <iframe
          style={{ "border-radius": "12px" }}
          src="https://open.spotify.com/embed/playlist/37i9dQZF1EphCu8c5qh6y7?utm_source=generator&theme=0"
          width="100%"
          height="152"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </>
    ),
  },
  {
    name: "TV Shows",
    icon: "tv",
    content: (
      <>
        I am a huge anime enthusiast. Some notable animes that I've watched
        include Naruto, Demon Slayer, <b>JoJo's Bizarre Adventure</b> (my
        personal favorite), and Vinland Saga.
      </>
    ),
  },
];

function Hobbies() {
  document.title = "Hobbies";
  return (
    <div>
      <h1 className="centered-header">Hobbies</h1>
      <div className="hobbies">
        {hobbies.map((hobby) => (
          <Hobby
            name={hobby.name}
            iconName={hobby.icon}
            content={hobby.content}
          />
        ))}
      </div>
    </div>
  );
}

export default Hobbies;
