import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";
import ContextMenu from "../ContextMenu";
import { mobile } from "../DetectMobile";
import highlight from "../constants/FileHighlight";
import useOutsideClick from "../hooks/OutsideClick";
import isList from "../constants/IsList";
import useOpenShortcut from "../hooks/UseOpenShortcut";
import useCopyShortcut from "../hooks/UseCopyShortcut";
import contrast from "../constants/Contrast";

function RedirectorFileButton(props) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);

  const wrapperRef = useRef();

  useOutsideClick(wrapperRef, () => setSelected(false));
  useOpenShortcut(selected, () => window.open(props.link, "_blank"), null);
  useCopyShortcut(selected, props.link);

  const isMobile = mobile;

  let i = icon(findIconDefinition({ prefix: "fab", iconName: props.icon }));
  console.log(i);
  if (i == null) {
    i = icon(findIconDefinition({ prefix: "fas", iconName: props.icon }));
  }

  const renderMenu = (p) => {
    //console.log("overlay props = ", p);
    return show ? (
      <Tooltip show={show} id="overlay-example" {...p}>
        <ContextMenu {...props} redirect={props.link} />
      </Tooltip>
    ) : (
      <span></span>
    );
  };

  function handleRightClick(e) {
    e.preventDefault();
    setSelected(true);
    if (e.type === "contextmenu") {
      console.log(e.type);
      setShow(true);
    } else {
      setShow(false);
    }
  }

  let fileName = props.label;
  let extension = "";
  const index = fileName.indexOf(".");
  if (index > -1) {
    fileName = fileName.substring(0, index);
    extension = props.label.substring(index);
  }

  return (
    <div ref={wrapperRef}>
      <div
        className={isList ? "folder-list" : "folder"}
        style={selected ? highlight : {}}
      >
        <Container>
          <OverlayTrigger
            placement={isList ? "bottom-start" : "right"}
            trigger="focus"
            delay={{ show: 200, hide: 10 }}
            overlay={renderMenu}
            onExited={() => setShow(false)}
          >
            <Button
              variant="link"
              size="lg"
              onDoubleClick={
                !isMobile
                  ? (e) => {
                      setSelected(false);
                      window.open(props.link, "_blank");
                    }
                  : null
              }
              onClick={
                !isMobile
                  ? (e) => handleRightClick(e)
                  : (e) => window.open(props.link, "_blank")
              }
              onContextMenu={(e) => handleRightClick(e)}
            >
              <FontAwesomeIcon
                icon={i}
                color="black"
                size={isList ? "2x" : "6x"}
                style={contrast}
              />
              {!isList && <br />}
              <span
                className="folder-text"
                style={localStorage.getItem("wallpaper") ? contrast : {}}
              >
                {fileName}
              </span>
              <span
                className="file-extension"
                style={localStorage.getItem("wallpaper") ? contrast : {}}
              >
                {extension}
              </span>
            </Button>
          </OverlayTrigger>
        </Container>
      </div>
    </div>
  );
}

export default RedirectorFileButton;
