import { useEffect, useCallback, useState } from "react";

const useCopyShortcut = (isSelected, path) => {
  useEffect(() => {
    const handleCopy = (evt) => {
      if (evt.key === "c" && evt.ctrlKey && isSelected) {
        evt.preventDefault();
        navigator.clipboard.writeText(path);
      }
    };
    document.addEventListener("keydown", handleCopy);
    return () => {
      document.removeEventListener("keydown", handleCopy);
    };
  });
};

export default useCopyShortcut;
