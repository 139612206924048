const pageURLs = [
  "/",
  "/about",
  "/about/hobbies",
  "/about/education",
  "/about/socials/instagram",
  "/about/socials/linkedin",
  "/about/socials",
  "/projects/coding",
  "/projects",
  "/projects/music",
  "/projects/music/unreleased tracks",
  "/projects/music/youtube",
  "/projects/music/youtube/about my channel",
  "/projects/music/soundcloud",
  "/tutorial",
];

export { pageURLs };
