import { useEffect, useCallback, useState } from "react";

const useContextMenu = () => {
  const [anchorPoint, setAnchorPoint] = useState([0, 0]);
  const [show, setShow] = useState(false);

  const cursorOnFolder = (divs) => {
    let b = false;
    if (divs.length < 4) {
      b = false;
    }
    divs.forEach((element) => {
      console.log(element.className);
      if (
        element.className &&
        (element.className === "folder" ||
          element.className === "folder-list" ||
          element.className === "container-fluid" ||
          element.className === "footer")
      ) {
        if (!b) {
          b = true;
        }
      }
    });
    return b;
  };

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      var divList = event.composedPath().slice(1, 5);
      if (!cursorOnFolder(divList)) {
        setAnchorPoint([event.pageX, event.pageY]);
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [setShow, setAnchorPoint]
  );

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });
  return { anchorPoint, show };
};

export default useContextMenu;
