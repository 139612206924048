import React from "react";

function NotFound() {
  document.title = "404 Not Found";
  return (
    <div className="page-not-found">
      <h1>This page does not exist...</h1>
    </div>
  );
}

export default NotFound;
