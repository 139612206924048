import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import contrast from "./constants/Contrast";

function LinkedIn() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://platform.linkedin.com/badges/js/profile.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    document.title = "LinkedIn";

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="page">
      <h1 style={contrast}>LinkedIn</h1>
      <div className="linkedin-badge">
        <Card>
          <div
            class="badge-base LI-profile-badge"
            //className="linkedin-badge"
            data-locale="en_US"
            data-size="medium"
            data-theme="light"
            data-type="VERTICAL"
            data-vanity="daniel-h-shi"
            data-version="v1"
          >
            <a
              class="badge-base__link LI-simple-link"
              href="https://www.linkedin.com/in/daniel-h-shi?trk=profile-badge"
            >
              Daniel Shi
            </a>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default LinkedIn;
