import React from "react";

function YoutubeAbout() {
  document.title = "About my Channel";
  return (
    <div className="youtube">
      <h1>About My Youtube Channel</h1>
      <p>
        Many of you already know that I love to produce music. This is where my
        best work resides!{" "}
        <a href="https://www.youtube.com/channel/UCTz21CTFHqFS9_fL9mMQN0w">
          Drown yourself
        </a>{" "}
        in a variety of Hip-Hop instrumentals and beats that suit almost any
        mood. Also, please consider dropping a like and subscribing, it truly
        means a lot to me.
      </p>
    </div>
  );
}

export default YoutubeAbout;
