import React from "react";
import "react-folder-tree/dist/style.css";
import { useLocation } from "react-router-dom";
import FileButton from "./Buttons/FileButton";
import FolderButton from "./Buttons/FolderButton";
import { mobile } from "./DetectMobile";
import Drag from "./Drag";
import contrast from "./constants/Contrast";
import isList from "./constants/IsList";

function Home() {
  const location = useLocation();

  document.title = "Home";

  // useEffect(() => {
  //   if (location.prev === "/") {
  //     window.location.reload();
  //   }
  // }, []);

  var files = [
    <FolderButton
      label="About"
      size="2MB"
      created="12/06/2021"
      location={window.location.pathname}
      files={3}
      folders={1}
    />,
    <FolderButton
      label="Projects"
      size=">700MB"
      created="12/06/2021"
      location={window.location.pathname}
      files={0}
      folders={2}
    />,
    !mobile && (
      <FileButton
        disabled={mobile}
        label="Terminal"
        link="/terminal"
        icon="terminal"
      />
    ),
    <FileButton label="tutorial.txt" link="/tutorial" icon="file-alt" />,
  ];

  return (
    <div className="page">
      <h1 style={contrast}>Home</h1>
      <div className={isList ? "list-view" : "folders"}>
        <Drag files={files} />
      </div>
    </div>
  );
}

export default Home;
