import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";

function Hobby(props) {
  const { iconName, name, content } = props;

  let i = icon(findIconDefinition({ prefix: "fab", iconName: iconName }));
  console.log(i);
  if (i == null) {
    i = icon(findIconDefinition({ prefix: "fas", iconName: iconName }));
  }

  return (
    <div className="hobby">
      <Container>
        <Row>
          <Col sm="auto">
            <h4>{name}</h4>
          </Col>
        </Row>
        <Row>
          <Col sm="auto">
            <FontAwesomeIcon icon={i} color="black" size="6x" />
          </Col>
          <Col>
            <p>{content}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hobby;
