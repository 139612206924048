import React from "react";
import { Button, InputGroup } from "react-bootstrap";

function ViewSettings() {
  function handleChangeView(view) {
    localStorage.setItem("view", view);
    window.location.reload();
  }

  return (
    <div>
      <InputGroup>
        <InputGroup.Text>View As: </InputGroup.Text>
        <Button
          variant="outline-secondary"
          disabled={
            localStorage.getItem("view") === "icons" ||
            !localStorage.getItem("view")
          }
          onClick={() => handleChangeView("icons")}
        >
          Icons
        </Button>
        <Button
          variant="outline-secondary"
          disabled={localStorage.getItem("view") === "list"}
          onClick={() => handleChangeView("list")}
        >
          List
        </Button>
      </InputGroup>
    </div>
  );
}

export default ViewSettings;
