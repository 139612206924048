import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import ContextMenu from "../ContextMenu";
import { mobile } from "../DetectMobile";
import contrast from "../constants/Contrast";
import highlight from "../constants/FileHighlight";
import isList from "../constants/IsList";
import useOutsideClick from "../hooks/OutsideClick";
import useCopyShortcut from "../hooks/UseCopyShortcut";
import useOpenShortcut from "../hooks/UseOpenShortcut";

function RedirectFolderButton(props) {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);
  const wrapperRef = useRef();

  useOutsideClick(wrapperRef, () => setSelected(false));
  useOpenShortcut(selected, () => window.open(props.link, "_blank"), null);
  useCopyShortcut(selected, props.link);

  let fileName = props.label;

  const isMobile = mobile;

  const renderMenu = (p) => {
    //console.log("overlay props = ", p);
    return show ? (
      <Tooltip show={show} id="overlay-example" {...p}>
        <ContextMenu {...props} redirect={props.link} />
      </Tooltip>
    ) : (
      <span></span>
    );
  };

  function handleRightClick(e) {
    setSelected(true);
    e.preventDefault();
    if (e.type === "contextmenu") {
      console.log(e.type);
      setShow(true);
    } else {
      setShow(false);
    }
  }

  return (
    <div ref={wrapperRef}>
      <div
        className={isList ? "folder-list" : "folder"}
        style={selected ? highlight : {}}
      >
        <Container>
          <OverlayTrigger
            placement={isList ? "bottom-start" : "right"}
            trigger="focus"
            delay={{ show: 200, hide: 10 }}
            overlay={renderMenu}
            onExited={() => setShow(false)}
          >
            <Button
              variant="link"
              size="lg"
              onDoubleClick={
                !isMobile
                  ? () => {
                      setSelected(false);
                      window.open(props.link, "_blank");
                    }
                  : null
              }
              onClick={
                isMobile
                  ? (e) => window.open(props.link, "_blank")
                  : (e) => handleRightClick(e)
              }
              onContextMenu={(e) => handleRightClick(e)}
            >
              <FontAwesomeIcon
                icon="folder-open"
                color="#5999ff"
                size={isList ? "2x" : "6x"}
                style={contrast}
              />
              {!isList && <br />}
              <span style={localStorage.getItem("wallpaper") ? contrast : {}}>
                {fileName}
              </span>
            </Button>
          </OverlayTrigger>
        </Container>
      </div>
    </div>
  );
}

export default RedirectFolderButton;
