import React from "react";

function ContextMenu(props) {
  const { label, properties, setShowProperties, redirect, downloadFile, file } =
    props;
  const path =
    label.indexOf(".") > -1 ? label.substring(0, label.indexOf(".")) : label;

  const route =
    redirect?.length > 0
      ? redirect
      : window.location.pathname + "/" + encodeURIComponent(path);

  function handleCopy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(
      window.location.href + "/" + encodeURIComponent(path)
    );
  }

  const contextMenu = !downloadFile ? (
    <dl>
      <dd>
        <a href={route}>
          <b>Open</b>
        </a>
      </dd>
      <dd>
        <a href={route} target="_blank">
          Open in new tab
        </a>
      </dd>
      <dd>
        <a href="" onClick={(e) => handleCopy(e)}>
          Copy
        </a>
      </dd>
      {properties && (
        <dd>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setShowProperties(true);
            }}
          >
            Properties
          </a>
        </dd>
      )}
    </dl>
  ) : (
    <dl>
      <dd>
        <a href="" onClick={(e) => downloadFile(e, file)}>
          Download
        </a>
      </dd>
    </dl>
  );

  return contextMenu;
}

export default ContextMenu;
