import React from "react";
import FolderButton from "./Buttons/FolderButton";
import isList from "./constants/IsList";
import contrast from "./constants/Contrast";
import Drag from "./Drag";

function Projects() {
  var files = [
    <FolderButton
      label="Music"
      size="204MB"
      created="12/06/2021"
      location={window.location.pathname}
      files={1}
      folders={2}
    />,
    <FolderButton
      label="Coding"
      size="584MB"
      created="12/06/2021"
      location={window.location.pathname}
      files={3}
      folders={1}
    />,
  ];

  document.title = "Projects";
  return (
    <div className="page">
      <h1 style={contrast}>Projects</h1>
      <div className={isList ? "list-view" : "folders"}>
        <Drag files={files} />
      </div>
    </div>
  );
}

export default Projects;
