import React from "react";
import { Card } from "react-bootstrap";

function Soundcloud() {
  document.title = "SoundCloud";
  const viper = (
    <div>
      <iframe
        width="100%"
        height={300}
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1097340379&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      />
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontWeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/daniel-h-shi"
          title="Daniel Shi"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Daniel Shi
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/daniel-h-shi/viper-vip"
          title="Viper - VIP"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Viper - VIP
        </a>
      </div>
    </div>
  );

  const orion = (
    <div>
      <iframe
        width="100%"
        height={300}
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1096856779&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      />
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontWeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/daniel-h-shi"
          title="Daniel Shi"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Daniel Shi
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/daniel-h-shi/orion"
          title="ORION"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          ORION
        </a>
      </div>
    </div>
  );

  const crescent = (
    <div>
      <iframe
        width="100%"
        height={300}
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/851756653&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      />
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontWeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/daniel-h-shi"
          title="Daniel Shi"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Daniel Shi
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/daniel-h-shi/crescent"
          title="Crescent"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Crescent
        </a>
      </div>
    </div>
  );

  const lavenderNight = (
    <div>
      <iframe
        width="100%"
        height={300}
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/851752099&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      />
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontWeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/daniel-h-shi"
          title="Daniel Shi"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Daniel Shi
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/daniel-h-shi/lavender-night"
          title="Lavender Night"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Lavender Night
        </a>
      </div>
    </div>
  );

  const joyride = (
    <div>
      <iframe
        width="100%"
        height={300}
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1076809618&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      />
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontWeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/daniel-h-shi"
          title="Daniel Shi"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          Daniel Shi
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/daniel-h-shi/sets/joyride-ep"
          title="JOYRIDE - EP"
          target="_blank"
          style={{ color: "#cccccc", textDecoration: "none" }}
        >
          JOYRIDE - EP
        </a>
      </div>
    </div>
  );

  return (
    <div className="soundcloud">
      <h1 className="centered-header">SoundCloud</h1>
      <p
      // className="soundcloud-tracks"
      // style={{ justifyContent: "center", alignContent: "center" }}
      >
        <span>
          I like to produce many different generes of music. My SoundCloud puts
          all my tracks in the booming genre of EDM on full display. Below are a
          few tracks that I'm super proud of. Consider giving me a follow if you
          enjoy what you hear.
        </span>
        <br />

        <Card>{viper}</Card>
        <Card>{orion}</Card>
        <Card>{crescent}</Card>
        <Card>{lavenderNight}</Card>
        <Card>{joyride}</Card>
      </p>
    </div>
  );
}

export default Soundcloud;
