import React from "react";
import { ProgressBar } from "react-bootstrap";

function AudioBar(props) {
  const { duration } = props;
  //const [curTime, setCurTime] = useState(0);
  const { isPlaying, curTime } = props;
  const curPercentage = (curTime / duration) * 100;


  return (
    <div>
      <ProgressBar label now={curPercentage} />
    </div>
  );
}

export default AudioBar;
