import "./App.css";
import Home from "./components/Home";

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  Navigate,
} from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "font-awesome/css/font-awesome.min.css";

import About from "./components/About";
import Socials from "./components/Socials";
import Instagram from "./components/Instagram";
import LinkedIn from "./components/LinkedIn";
import Footer from "./components/Footer";
import Education from "./components/Education";
import Projects from "./components/Projects";
import Music from "./components/Music";
import Youtube from "./components/Youtube";
import YoutubeAbout from "./components/YoutubeAbout";
import Shell from "./components/Shell";
import Header from "./components/Header";
import ExitScreen from "./components/ExitScreen";
import Coding from "./components/Coding";
import UnreleasedMusic from "./components/UnreleasedMusic";
import NotFound from "./components/NotFound";
import Terminal from "./components/Terminal";
import Hobbies from "./components/Hobbies";
import Soundcloud from "./components/Soundcloud";
import MeetDS from "./components/MeetDS";
import Start from "./components/Start";
import GeneralContextMenu from "./components/GeneralContextMenu";
import Tutorial from "./components/Tutorial";
import changeWallpaper from "./components/constants/SetWallpaper";

library.add(fas, fab);
//import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("view")) {
      localStorage.setItem("view", "icons");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("wallpaper")) {
      changeWallpaper();
    }
  });

  let title = window.location.pathname;
  title = title.substring(title.lastIndexOf("/") + 1);
  document.title = title;

  return (
    <div>
      <GeneralContextMenu />
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Redirect from="/home" to="/" />
          <Route exact path="/shell">
            <Shell />
          </Route>
          {/* <Route exact path="/home" component={Home} /> */}
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/about/socials">
            <Socials />
          </Route>
          <Route exact path="/about/socials/instagram">
            <Instagram />
          </Route>
          <Route exact path="/about/socials/linkedin">
            <LinkedIn />
          </Route>
          <Route exact path="/about/education">
            <Education />
          </Route>
          <Route exact path="/projects">
            <Projects />
          </Route>
          <Route exact path="/projects/music">
            <Music />
          </Route>
          <Route exact path="/projects/music/youtube">
            <Youtube />
          </Route>
          <Route exact path="/projects/music/youtube/about my channel">
            <YoutubeAbout />
          </Route>
          <Route exact path="/exit">
            <ExitScreen />
          </Route>
          <Route exact path="/projects/coding" component={Coding} />
          <Route
            exact
            path="/projects/music/unreleased tracks"
            component={UnreleasedMusic}
          />
          <Route exact path="/terminal" component={Terminal} />
          <Route exact path="/about/hobbies" component={Hobbies} />
          <Route
            exact
            path="/projects/music/soundcloud"
            component={Soundcloud}
          />
          <Route exact path="/about/meet ds" component={MeetDS} />
          <Route exact path="/tutorial" component={Tutorial} />
          <Route component={NotFound} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
