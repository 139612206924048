import React from "react";
import { Button, Modal } from "react-bootstrap";

function FileProperties(props) {
  const {
    label,
    size,
    created,
    showProperties,
    setShowProperties,
    files,
    folders,
    location,
  } = props;

  return (
    <Modal
      show={showProperties}
      onHide={() => setShowProperties(false)}
      onExited={() => setShowProperties(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Name:&emsp;&emsp;{label}
        <br />
        Type:&emsp;&emsp;File folder
        <br />
        Location:&emsp;&emsp;{location}
        <br />
        Size:&emsp;&emsp;{size}
        <br />
        Contains:&emsp;&emsp;{files} {files !== 1 ? "Files" : "File"}, {folders}{" "}
        {folders !== 1 ? "Folders" : "Folder"}
        <br />
        Created:&emsp;&emsp;{created}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setShowProperties(false)}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FileProperties;
