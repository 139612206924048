const treeState = {
  name: "Home",
  url: "/",
  isOpen: true, // this folder is opened, we can see it's children
  children: [
    {
      name: "About",
      url: "/about",
      children: [
        { name: "Meet DS", url: "/about/meet ds" },
        {
          name: "Education",
          url: "/about/education",
        },
        { name: "Hobbies", url: "/about/hobbies" },
        {
          name: "Socials",
          url: "/about/socials",
          children: [
            { name: "Instagram", url: "/about/socials/instagram" },
            { name: "LinkedIn", url: "/about/socials/linkedin" },
          ],
        },
      ],
    },
    {
      name: "Projects",
      url: "/projects",
      children: [
        {
          name: "Coding",
          url: "/projects/coding",
          children: [
            {
              name: "Corec Tracker",
              redirectTo: "https://github.com/danielshii/Corec-Tracker",
              children: [],
            },
            { name: "Reversi", downloadName: "Reversi.jar" },
            { name: "Connect4", downloadName: "connect4.jar" },
            { name: "Gravity Pong", downloadName: "gravity pong.jar" },
          ],
        },
        {
          name: "Music",
          isOpen: false,
          url: "/projects/music",
          children: [
            {
              name: "Youtube",
              isOpen: false,
              url: "/projects/music/youtube",
              children: [
                {
                  name: "About My Channel",
                  url: "/projects/music/youtube/about my channel",
                },
                {
                  name: "My Latest Beat",
                  redirectTo:
                    "https://tools.tastethecode.com/youtube-latest/r/recent-video",
                },
                {
                  name: "Close To You",
                  redirectTo: "https://www.youtube.com/watch?v=wXqoaanmU1M",
                },
                {
                  name: "MOBBIN",
                  redirectTo: "https://www.youtube.com/watch?v=_9N3_x5pn48",
                },
                {
                  name: "WILD WEST",
                  redirectTo: "https://www.youtube.com/watch?v=pqjGgV5g36U",
                },
              ],
            },
            {
              name: "SoundCloud",
              url: "/projects/music/soundcloud",
            },
            {
              name: "Unreleased Tracks",
              url: "/projects/music/unreleased tracks",
              isOpen: false,
              children: [
                { name: "Happy Now (Remix)", audioFile: "happy now remix.mp3" },
                { name: "Some Riddim", audioFile: "VR Riddim.mp3" },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Tutorial",
      url: "/tutorial",
    },
  ],
};

export { treeState };
