import DownloadableFileButton from "./Buttons/DownloadableFileButton";
import RedirectFolderButton from "./Buttons/RedirectFolderButton";
import isList from "./constants/IsList";
import contrast from "./constants/Contrast";
import Drag from "./Drag";

function Coding() {
  document.title = "Coding";

  var files = [
    <RedirectFolderButton
      link="https://github.com/danielshii/Corec-Tracker"
      label="Corec Tracker"
    />,
    <DownloadableFileButton
      label="Reversi.jar"
      file="Reversi.jar"
      icon="java"
    />,
    <DownloadableFileButton
      label="Connect4.jar"
      file="connect4.jar"
      icon="java"
    />,
    <DownloadableFileButton
      label="Gravity_Pong.jar"
      file="gravity pong.jar"
      icon="java"
    />,
  ];

  return (
    <div>
      <div className="coding">
        <div className="page">
          <h1 style={contrast}>Coding</h1>
          <div className={isList ? "list-view" : "folders"}>
            <Drag files={files} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coding;
