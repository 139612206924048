import { useEffect, useCallback, useState } from "react";

const useOpenShortcut = (isSelected, callback, path) => {
  useEffect(() => {
    const handleOpen = (evt) => {
      console.log(evt);
      if (evt.key === "o" && evt.ctrlKey && isSelected) {
        evt.preventDefault();
        console.log("open");
        callback(path); //Do what you want to handle in the callback
      }
    };
    document.addEventListener("keydown", handleOpen);
    return () => {
      document.removeEventListener("keydown", handleOpen);
    };
  });
};

export default useOpenShortcut;
