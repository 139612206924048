import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Popover,
  Offcanvas,
  Modal,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTimes,
  faStream,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import Search from "./Search";
import { treeState } from "./constants/FileStructure";
import FolderTree from "react-folder-tree";
import axios from "axios";
import fileDownload from "js-file-download";
import Terminal from "./Terminal";
import { mobile } from "./DetectMobile";
import ViewSettings from "./ViewSettings";
import changeWallpaper from "./constants/SetWallpaper";

function Header() {
  const history = useHistory();
  const location = useLocation();
  const [title, setTitle] = useState("");

  const audio = useRef(new Audio(""));

  const [showSidebar, setShowSidebar] = useState(false);
  const [showMusicStop, setShowMusicStop] = useState(false);
  const [showTerminal, setShowTerminal] = useState(false);
  const [wallpaper, setWallpaper] = useState(null);
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    setTitle(document.title);
  }, [window.location.pathname]);

  function downloadFile(f) {
    try {
      axios
        .get(`/files/${f}`, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, f);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const sidebar = (
    <Offcanvas show={showSidebar} onHide={() => setShowSidebar(false)}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <span>
            <h1>Files</h1>
            {showMusicStop && (
              <Button
                variant="light"
                onClick={() => {
                  audio.current.pause();
                  setShowMusicStop(false);
                }}
              >
                <FontAwesomeIcon icon="stop" />
              </Button>
            )}
          </span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <FolderTree
          data={treeState}
          initOpenStatus="custom"
          showCheckbox={false}
          readOnly
          onNameClick={(e) => {
            if ("redirectTo" in e.nodeData) {
              window.open(e.nodeData.redirectTo, "_blank");
              setShowSidebar(false);
            } else if ("downloadName" in e.nodeData) {
              downloadFile(e.nodeData.downloadName);
              setShowSidebar(false);
            } else if ("audioFile" in e.nodeData) {
              audio.current.src = "/files/music/" + e.nodeData.audioFile;
              audio.current.play();
              setShowMusicStop(true);
            } else {
              window.location.replace(e.nodeData.url);
              // history.push(e.nodeData.url);
              console.log(e);
              setShowSidebar(false);
            }
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );

  const search = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Search />
      </Popover.Body>
    </Popover>
  );

  const terminal = (
    <Modal
      size="xl"
      keyboard={true}
      show={showTerminal}
      onHide={() => setShowTerminal(false)}
    >
      <Modal.Header>
        <Modal.Title>
          <Container>
            <Row>
              <Col>
                <FontAwesomeIcon icon="terminal" />
              </Col>
              <Col xs>
                <span className="center">Terminal</span>
              </Col>
            </Row>
          </Container>
        </Modal.Title>
      </Modal.Header>
      <Container>
        <Terminal />
      </Container>
    </Modal>
  );

  const viewSettings = (
    <Popover>
      <Popover.Body>
        <ViewSettings />
      </Popover.Body>
    </Popover>
  );

  const uploadWallpaper = (
    <Popover>
      <Popover.Header>
        <h4>Wallpaper</h4>
      </Popover.Header>
      <Popover.Body>
        <input
          className="choose-wallpaper"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          onChange={(e) => setWallpaper(e.target.files[0])}
          style={{ marginBottom: "5%" }}
        ></input>
        <ButtonGroup>
          {wallpaper && (
            <Button
              variant="outline-dark"
              disabled={!wallpaper}
              onClick={handleUploadFile}
            >
              Use as Wallpaper
            </Button>
          )}
          <Button variant="outline-dark" onClick={resetWallpaper}>
            Reset
          </Button>
        </ButtonGroup>

        {fileError.length > 0 && (
          <div style={{ color: "red", marginTop: "5%" }}>{fileError}</div>
        )}
      </Popover.Body>
    </Popover>
  );

  function resetWallpaper() {
    setWallpaper(null);
    localStorage.removeItem("wallpaper");
    window.location.reload();
  }

  async function handleUploadFile() {
    const formData = new FormData();
    formData.append("file", wallpaper);
    formData.append("upload_preset", "upload_wallpaper");

    // console.log(formData);
    // console.log(wallpaper);
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${"dgs7nrc81"}/image/upload`,
        formData
      )
      .then((response) => {
        console.log(response.data.secure_url);
        localStorage.setItem("wallpaper", response.data.secure_url);
        setFileError("");
        changeWallpaper();
        setWallpaper(null);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setFileError("Could not upload file.");
      });
  }

  return (
    <div className="header">
      {terminal}

      <div>
        <Container fluid>
          <Row>
            <Col>
              <span className="left">
                <ButtonGroup>
                  <Button variant="light" onClick={() => setShowSidebar(true)}>
                    <FontAwesomeIcon icon={faStream} />
                  </Button>
                  <Button
                    variant="light"
                    id="home"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/");
                    }}
                  >
                    <FontAwesomeIcon icon="home" />
                  </Button>
                  <OverlayTrigger
                    trigger="click"
                    overlay={search}
                    placement={mobile ? "bottom" : "right"}
                  >
                    <Button variant="light">
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  </OverlayTrigger>
                  {!mobile && (
                    <Button
                      disabled={
                        window.location.pathname.toLowerCase() ===
                        "/home/terminal"
                      }
                      variant="light"
                      onClick={() => setShowTerminal(true)}
                    >
                      <FontAwesomeIcon icon="terminal" />
                    </Button>
                  )}
                  <OverlayTrigger
                    trigger="click"
                    overlay={viewSettings}
                    placement={mobile ? "bottom" : "right"}
                  >
                    <Button variant="light">
                      <FontAwesomeIcon icon="eye" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    trigger="click"
                    overlay={uploadWallpaper}
                    placement={mobile ? "bottom" : "right"}
                    onExit={() => {
                      setFileError("");
                      setWallpaper(null);
                    }}
                  >
                    <Button variant="light">
                      <FontAwesomeIcon icon="image" />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </span>
            </Col>
            <Col lg="auto">
              <span className="center">
                <h3>{title}</h3>
              </span>
            </Col>
            <Col>
              <span className="right">
                <ButtonGroup>
                  <Button variant="success" onClick={() => history.goBack()}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                  <Button variant="warning" onClick={() => history.goForward()}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!mobile) {
                        history.push("/exit");
                      } else {
                        sessionStorage.removeItem("authenticated");
                        history.push("/");
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </ButtonGroup>
              </span>
            </Col>
          </Row>
        </Container>
      </div>

      {sidebar}
    </div>
  );
}

export default Header;
