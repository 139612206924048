import React, { useState, useEffect } from "react";
import { pageURLs } from "./constants/PageURLs";
import { mobile } from "./DetectMobile";

const helpMenu = (
  <div>
    {"echo <output> - writes output to the screen"}
    <br />
    {
      "goto <path> - redirects to the specified path. Works for both absolute and relative paths."
    }
    <br />
    {"pwd - prints the current pathname"}
    <br />
    {
      "ls - lists all routes in the current path (not including download and redirect files)"
    }
    <br />
    {"exit - exit the website"}
  </div>
);

const errMessage = "Not a valid command";

function Terminal() {
  document.title = "Terminal";
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [error, setError] = useState(false);
  const [commandList, setCommandList] = useState([]);
  const [commandIndex, setCommandIndex] = useState(0);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyPress, {
      once: true,
    });
    return () => {
      document.body.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    if (mobile) {
      window.history.go(-1);
    }
  }, []);

  async function handleLS() {
    let currentPath = window.location.pathname.toLowerCase();
    let checkDups = [];
    let files = [];
    pageURLs.forEach((url) => {
      url = url.toLowerCase();
      console.log(url);
      if (url.indexOf(currentPath) === 0 && url.length > currentPath.length) {
        let tempRelative = url.substring(currentPath.length + 1);
        let relativePath = "";
        if (tempRelative.indexOf("/") > -1)
          relativePath =
            "/" + tempRelative.substring(0, tempRelative.indexOf("/"));
        else relativePath = "/" + tempRelative;
        if (!checkDups.includes(relativePath)) {
          checkDups.push(relativePath);
          files.push({
            relativePath: relativePath,
            absolutePath: currentPath + relativePath,
          });
        }
      }
    });
    console.log(checkDups);
    console.log(files);
    setOutput(
      <div>
        {files.map((route) => {
          return (
            <span>
              <a href={route.absolutePath}>{route.relativePath}</a>
              <br />
            </span>
          );
        })}
      </div>
    );
  }

  async function executeCommand(command) {
    setError(false);
    command = command.trim();
    let newCommandList = [...commandList];
    newCommandList.push(command);
    setCommandList(newCommandList);
    //setCommandIndex(commandList.length - 1);
    console.log(commandList, commandIndex);
    let args = [command];
    if (command.indexOf(" ") > -1) {
      args = [
        command.substring(0, command.indexOf(" ")),
        command.substring(command.indexOf(" ") + 1),
      ];
    }
    console.log(args);
    if (args[0] === "echo") {
      setOutput(args.length > 1 ? args[1] : "");
    } else if (command === "help") {
      setOutput(helpMenu);
    } else if (args[0] === "goto") {
      if (args.length > 1) {
        const currentPath = window.location.pathname;
        if (command.substring(5) === "../") {
          window.location.replace(
            currentPath.substring(0, currentPath.lastIndexOf("/"))
          );
        } else if (command.indexOf("/") === -1) {
          //console.log(window.location.pathname + "/" + command.substring(5));
          window.location.replace(currentPath + "/" + args[1]);
        } else {
          window.location.replace(args[1]);
        }
        setOutput("Redirecting to " + args[1]);
      } else {
        handleInvalidInput();
      }
    } else if (command === "pwd") {
      setOutput(window.location.pathname);
    } else if (command === "ls") {
      await handleLS();
    } else if (command === "exit") {
      setOutput("exiting...");
      window.location.replace("/exit");
    } else {
      handleInvalidInput();
    }
  }

  function handleInvalidInput(errorMessage) {
    setError(true);
    if (errorMessage) {
      setOutput(errorMessage);
    } else {
      setOutput(errMessage);
    }
  }

  async function handleKeyPress(e) {
    e.preventDefault();
    //console.log(e.key);
    //console.log(input);
    switch (e.key) {
      case "Backspace":
        if (input.length <= 1) {
          document.body.removeEventListener("keydown", handleKeyPress);
          document.body.addEventListener("keydown", handleKeyPress, {
            once: true,
          });
          setInput("");
        } else {
          setInput(input.slice(0, input.length - 1));
        }
        break;
      case "Enter":
        setCommandIndex(commandList.length);
        await executeCommand(input);
        setInput("");
        break;
      case "ArrowUp":
        setCommandIndex(commandIndex - 1);
        if (commandIndex < 0) {
          setCommandIndex(0);
        }
        const prevCommand = commandList[commandIndex];
        setInput(prevCommand ? prevCommand : "");
        break;
      case "ArrowDown":
        const nextCommand = commandList[commandIndex];
        setCommandIndex(commandIndex + 1);
        if (commandIndex > commandList.length - 1) {
          setCommandIndex(commandList.length - 1);
        }
        setInput(nextCommand ? nextCommand : "");
        break;
      default:
        setInput(input + e.key);
        break;
    }
  }

  return (
    <div className="terminal">
      <h2>
        <span>
          {"> "}
          {input}
        </span>
        <br />
        <br />
        <br />
        <div style={{ color: error ? "red" : "white" }}>{output}</div>
      </h2>
    </div>
  );
}

export default Terminal;
