const contrast = localStorage.getItem("wallpaper")
  ? {
      color: "white",
      mixBlendMode: "difference",
    }
  : {};

export default contrast;

//   color: "white",
//   mixBlendMode: "difference",

// background: "inherit",
//       backgroundClip: "text",
//       color: "transparent",
//       filter: "invert(1)",
