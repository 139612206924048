import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function ExitScreen() {
  const history = useHistory();
  const [disconnect, setDisconnect] = useState("");

  useEffect(() => {
    if (disconnect === "Yes") {
      sessionStorage.removeItem("authenticated");
      history.push("/");
    } else if (disconnect === "No") {
      history.goBack();
    }
  }, [disconnect]);

  return (
    <div className="shell">
      <span>
        Disconnect?
        <Button
          id="Yes"
          variant="link"
          onClick={(e) => setDisconnect(e.target.id)}
        >
          Yes
        </Button>
        <Button
          id="No"
          variant="link"
          onClick={(e) => setDisconnect(e.target.id)}
        >
          No
        </Button>
        <br />
        <br />
      </span>
    </div>
  );
}

export default ExitScreen;
