import React from "react";
import { mobile } from "./DetectMobile";

function Footer() {
  const isMobile = mobile;

  return (
    <div className="footer">
      <i>
        {!isMobile
          ? "Note: Visit pages by double clicking the buttons. It is supposed to mimic a file explorer, after all!"
          : "Looks like you're viewing this page on a mobile device. In that case, you can simply single-tap on folders and files to open them."}
      </i>
    </div>
  );
}

export default Footer;
