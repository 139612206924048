import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AudioPlayer from "../AudioPlayer";
import { mobile } from "../DetectMobile";
import contrast from "../constants/Contrast";
import highlight from "../constants/FileHighlight";
import isList from "../constants/IsList";
import useOutsideClick from "../hooks/OutsideClick";
import useOpenShortcut from "../hooks/UseOpenShortcut";

function MusicButton(props) {
  const history = useHistory();

  const isMobile = mobile;

  const [audioTime, setAudioTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selected, setselected] = useState(false);

  const wrapperRef = useRef();

  useOutsideClick(wrapperRef, () => setselected(false));
  useOpenShortcut(selected, playFile, null);

  let fileName = props.label;
  let extension = "";
  const index = fileName.indexOf(".");
  if (index > -1) {
    fileName = fileName.substring(0, index);
    extension = props.label.substring(index);
  }

  let audio = new Audio("/files/music/" + props.file);

  function playFile() {
    setAudioTime(parseInt(audio.duration));
    setIsPlaying(true);
  }

  return (
    <div style={contrast} ref={wrapperRef}>
      <div
        className={isList ? "folder-list" : "folder"}
        style={selected ? highlight : {}}
      >
        <Container>
          <Button
            variant="link"
            size="lg"
            onDoubleClick={
              !isMobile
                ? () => {
                    setselected(false);
                    playFile();
                  }
                : null
            }
            onClick={isMobile ? () => playFile() : () => setselected(true)}
          >
            <FontAwesomeIcon
              icon="file-audio"
              color="black"
              size={isList ? "2x" : "6x"}
              style={contrast}
            />
          </Button>

          {!isList && <br />}
          <span className="folder-text" style={contrast}>
            {fileName}
          </span>
          <span className="file-extension" style={contrast}>
            {extension}
          </span>
        </Container>
      </div>
      <AudioPlayer
        isPlaying={isPlaying}
        time={audioTime}
        file={"/files/music/" + props.file}
      />
    </div>
  );
}

export default MusicButton;
