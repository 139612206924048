import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import fileDownload from "js-file-download";
import ContextMenu from "../ContextMenu";
import { mobile } from "../DetectMobile";
import highlight from "../constants/FileHighlight";
import useOutsideClick from "../hooks/OutsideClick";
import isList from "../constants/IsList";
import useOpenShortcut from "../hooks/UseOpenShortcut";
import contrast from "../constants/Contrast";

function DownloadableFileButton(props) {
  const history = useHistory();

  const isMobile = mobile;

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);

  const wrapperRef = useRef();

  useOutsideClick(wrapperRef, () => setSelected(false));

  let i = icon(findIconDefinition({ prefix: "fab", iconName: props.icon }));
  console.log(i);
  if (i == null) {
    i = icon(findIconDefinition({ prefix: "fas", iconName: props.icon }));
  }

  let fileName = props.label;
  let extension = "";
  const index = fileName.indexOf(".");
  if (index > -1) {
    fileName = fileName.substring(0, index);
    extension = props.label.substring(index);
  }
  useOpenShortcut(selected, downloadFile, props.file);

  function handleRightClick(e) {
    setSelected(true);
    e.preventDefault();
    if (e.type === "contextmenu") {
      console.log(e.type);
      setShow(true);
    } else {
      setShow(false);
    }
  }

  function downloadFile(f) {
    //e.preventDefault();
    try {
      axios
        .get(`/files/${f}`, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, f);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const renderMenu = (p) => {
    //console.log("overlay props = ", p);
    return show ? (
      <Tooltip show={show} id="overlay-example" {...p}>
        <ContextMenu {...props} downloadFile={downloadFile} />
      </Tooltip>
    ) : (
      <span></span>
    );
  };

  return (
    <div
      ref={wrapperRef}
      style={localStorage.getItem("wallpaper") ? contrast : {}}
    >
      <div
        className={isList ? "folder-list" : "folder"}
        style={selected ? highlight : contrast}
      >
        <Container>
          <OverlayTrigger
            placement={isList ? "bottom-start" : "right"}
            trigger="focus"
            delay={{ show: 300, hide: 10 }}
            overlay={renderMenu}
            onExited={() => setShow(false)}
          >
            <Button
              variant="link"
              size="lg"
              onDoubleClick={!isMobile ? (e) => downloadFile(props.file) : null}
              onClick={
                isMobile
                  ? (e) => downloadFile(props.file)
                  : (e) => handleRightClick(e)
              }
              onContextMenu={(e) => handleRightClick(e)}
            >
              <FontAwesomeIcon
                icon={i}
                color={extension !== ".jar" ? "black" : "white"}
                size={isList ? "2x" : "6x"}
                style={contrast}
              />
              {!isList && <br />}
              <span
                className="folder-text"
                style={localStorage.getItem("wallpaper") ? contrast : {}}
              >
                {fileName}
              </span>
              <span
                className="file-extension"
                style={localStorage.getItem("wallpaper") ? contrast : {}}
              >
                {extension}
              </span>
            </Button>
          </OverlayTrigger>
        </Container>
      </div>
    </div>
  );
}

export default DownloadableFileButton;
