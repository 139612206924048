import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ContextMenu from "../ContextMenu";
import { mobile } from "../DetectMobile";
import FileProperties from "../FileProperties";
import contrast from "../constants/Contrast";
import highlight from "../constants/FileHighlight";
import isList from "../constants/IsList";
import generateLink from "../hooks/GenerateLink";
import useOutsideClick from "../hooks/OutsideClick";
import useCopyShortcut from "../hooks/UseCopyShortcut";
import useOpenShortcut from "../hooks/UseOpenShortcut";

function FolderButton(props) {
  const history = useHistory();

  const isMobile = mobile;

  const { label, size, created } = props;
  const [show, setShow] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const wrapperRef = useRef();
  const [selected, setSelected] = useState(false);
  const path = generateLink(label);

  useOutsideClick(wrapperRef, () => setSelected(false));

  useOpenShortcut(selected, () => window.location.replace(path), path);
  useCopyShortcut(selected, path);

  const renderMenu = (p) => {
    //console.log("overlay props = ", p);
    return (
      <Tooltip id="overlay-example" {...p}>
        {show ? (
          <ContextMenu
            {...props}
            setShowProperties={setShowProperties}
            properties
          />
        ) : (
          <>
            Name: {label}
            <br />
            Size: {size}
            <br />
            Date Created: {created}
          </>
        )}
      </Tooltip>
    );
  };

  function handleRightClick(e) {
    setSelected(true);
    e.preventDefault();
    if (e.type === "contextmenu") {
      console.log(e.type);
      setShow(true);
    } else {
      setShow(false);
    }
  }

  return (
    <div ref={wrapperRef}>
      <FileProperties
        showProperties={showProperties}
        setShowProperties={setShowProperties}
        {...props}
      />
      <div
        className={isList ? "folder-list" : "folder"}
        style={selected ? highlight : {}}
      >
        <Container>
          <OverlayTrigger
            placement={isList ? "bottom-start" : "right"}
            trigger={show ? "focus" : "hover"}
            delay={{ show: show ? 100 : 800, hide: 10 }}
            overlay={renderMenu}
            onExited={() => setShow(false)}
          >
            <Button
              variant="link"
              size="lg"
              onDoubleClick={!isMobile ? (e) => history.push(path) : null}
              onClick={
                isMobile
                  ? (e) => history.push(path)
                  : (e) => handleRightClick(e)
              }
              onContextMenu={(e) => handleRightClick(e)}
            >
              <FontAwesomeIcon
                icon={faFolder}
                color="#5999ff"
                size={isList ? "2x" : "6x"}
                style={contrast}
              />
              {!isList && <br />}
              <span style={localStorage.getItem("wallpaper") ? contrast : {}}>
                {props.label}
              </span>
            </Button>
          </OverlayTrigger>
        </Container>
      </div>
    </div>
  );
}

export default FolderButton;
