import React from "react";
import FileButton from "./Buttons/FileButton";
import FolderButton from "./Buttons/FolderButton";
import Drag from "./Drag";
import contrast from "./constants/Contrast";
import isList from "./constants/IsList";

function Music() {
  document.title = "Music";

  var files = [
    <FolderButton
      label="Youtube"
      size="40MB"
      created="12/06/2021"
      location={window.location.pathname}
      files={6}
      folders={0}
    />,
    <FolderButton
      label="Unreleased Tracks"
      size="10MB"
      created="12/07/2021"
      location={window.location.pathname}
      files={2}
      folders={0}
    />,
    <FileButton label="SoundCloud.zip" icon="soundcloud" />,
  ];

  return (
    <div className="page">
      <h1 style={contrast}>Music</h1>
      <div className={isList ? "list-view" : "folders"}>
        <Drag files={files} />
      </div>
    </div>
  );
}

export default Music;
