import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Typing from "react-typing-animation";

const sshString = "ssh guest@danielshi.me";
const password = "**********";
const auth = "Authenticating...";
const connected = "Connected!";

function Shell() {
  const history = useHistory();
  document.title = "ssh";

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function redirect() {
    await sleep(2000);
    history.push({ pathname: "/home", prev: "/" });
  }

  useEffect(() => {
    if (sessionStorage.getItem("authenticated")) {
      window.location.replace("/home");
    }
  }, []);

  return (
    <div className="shell">
      <Typing
        onFinishedTyping={() => {
          sessionStorage.setItem("authenticated", true);
          redirect();
        }}
        speed={5}
      >
        <span>{sshString}</span>
        <Typing.Speed ms={1} />
        <br />
        <br />
        Password: <Typing.Speed ms={5} />
        {password}
        <Typing.Speed ms={1} />
        <br />
        <br />
        <span style={{ color: "yellow" }}>{auth}</span>
        <Typing.Reset count={1} delay={3000} />
        <Typing.Speed ms={1} />
        <br />
        <br />
        <span style={{ color: "lightgreen" }}>{connected}</span>
      </Typing>
      <div>
        <a
          href="/home"
          onClick={() => sessionStorage.setItem("authenticated", true)}
        >
          Skip
        </a>
      </div>
    </div>
  );
}

export default Shell;
