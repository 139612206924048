import React from "react";

function Education() {
  document.title = "Education";
  return (
    <div className="education">
      <h1>
        <u>Education</u>
      </h1>
      <h2>Troy High School</h2>
      <h3>Class of 2020</h3>
      <p>
        I tried to challenge myself as much as I could in high school by taking
        lots of AP classes. This would prove to be super beneficial for me when
        I got to college, since a lot of my AP credits that I had accumulated in
        high school contributed towards me having to take less college classes
        and thus being able to graduate early.
      </p>
      <br />
      <h2>Purdue University</h2>
      <h3>Class of 2023</h3>
      <p>
        Now, I am a junior (in standing) who is about to embark on his second
        semster college at Purdue Unversity. I am studying Computer Science,
        unsurprisingly, with a concentration in Software Engineering. I have
        taken courses such as OOP, C Programming, Computer Architecture, Data
        Structures & Algorithms, Systems Programming, and more.
      </p>
      <br />
      <h2>Future Goals</h2>
      <p>
        Since this coming Summer will be my last summer before I graduate, I am
        setting my eyes on obtaining an internship for a company that I can
        eventually commit to as a full time software developer. My shorter term
        goals are to elevate my GPA further by studying diligently and obtaining
        my Bachelor's Degree. I will try to have as much fun as I can in the
        process!
      </p>
    </div>
  );
}

export default Education;
