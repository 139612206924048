function getCSSRule(ruleName) {
  ruleName = ruleName.toLowerCase();
  var result = null;
  var find = Array.prototype.find;

  find.call(document.styleSheets, (styleSheet) => {
    result = find.call(styleSheet.cssRules, (cssRule) => {
      return (
        cssRule instanceof CSSStyleRule &&
        cssRule.selectorText.toLowerCase() == ruleName
      );
    });
    return result != null;
  });
  return result;
}

const changeBackground = (element) => {
  element.style.backgroundImage = `url("${localStorage.getItem("wallpaper")}")`;
  element.style.backgroundSize = "cover";
  element.style.backgroundRepeat = "no-repeat";
  element.style.backgroundAttachment = "fixed";
};

const changeWallpaper = () => {
  var page = getCSSRule(".page");
  console.log(page);
  changeBackground(page);
};

export default changeWallpaper;
