import React from "react";
import FileButton from "./Buttons/FileButton";
import FolderButton from "./Buttons/FolderButton";
import isList from "./constants/IsList";
import contrast from "./constants/Contrast";
import Drag from "./Drag";

function About() {
  document.title = "About";

  var files = [
    <FolderButton
      label="Socials"
      size="2KB"
      created="12/06/2021"
      location={window.location.pathname}
      files={2}
      folders={1}
    />,
    <FileButton label="Meet DS.diagcab" icon="portrait" />,
    <FileButton label="Education.txt" icon="graduation-cap" />,
    <FileButton label="Hobbies.txt" icon="running" />,
  ];

  return (
    <div className="page">
      <h1 style={contrast}>About</h1>
      <div className={isList ? "list-view" : "folders"}>
        <Drag files={files} />
      </div>
    </div>
  );
}

export default About;
