import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { pageURLs } from "./constants/PageURLs";

function Search() {
  let p = pageURLs.map((url, index) => {
    return {
      title: url.substring(url.lastIndexOf("/") + 1),
      url: url,
    };
  });
  p.sort((a, b) => (a.title > b.title ? 1 : -1));

  const [searchResults, setSearchResults] = useState(p);

  function generateSuggestions(input) {
    let results = [];
    pageURLs.forEach((url) => {
      let title = url.substring(url.lastIndexOf("/") + 1);
      if (
        title.toLowerCase().indexOf(input.toLowerCase()) > -1 &&
        title !== "Search"
      ) {
        results.push({ title: title, url: url });
      }
    });
    //results.sort();
    results.sort((a, b) => (a.title > b.title ? 1 : -1));
    setSearchResults(results);
  }

  return (
    <div className="search">
      <Form.Control
        placeholder="Search for file or directory"
        onChange={(e) => generateSuggestions(e.target.value)}
      />
      {searchResults.map((result, index) => (
        <div>
          <a href={result.url}>{result.title}</a>
          <br />
        </div>
      ))}
    </div>
  );
}
export default Search;
