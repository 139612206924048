import React from "react";
import { Card } from "react-bootstrap";
import nametag from "../images/daniel.shii_nametag.png";
import contrast from "./constants/Contrast";

function Instagram() {
  document.title = "Instagram";
  return (
    <div className="page">
      <h1 style={contrast}>Instagram</h1>
      <div className="instagram">
        <Card>
          <Card.Header>
            <h3>
              <a href="http://www.instagram.com/daniel.shii">@daniel.shii</a>
            </h3>
          </Card.Header>
          <Card.Text>
            <img src={nametag} alt="Oops! We couldn't find the nametag..." />
          </Card.Text>
          <Card.Body>Scan and follow!</Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Instagram;
