import React from "react";
import FileButton from "./Buttons/FileButton";
import RedirectFolderButton from "./Buttons/RedirectFolderButton";
import isList from "./constants/IsList";
import contrast from "./constants/Contrast";
import Drag from "./Drag";

function Socials() {
  document.title = "Socials";

  var files = [
    <RedirectFolderButton
      link="https://github.com/danielshii"
      label="Github"
    />,
    <FileButton label="Instagram.jpg" icon="instagram" />,
    <FileButton label="LinkedIn.png" icon="linkedin" />,
  ];

  return (
    <div className="page">
      <h1 style={contrast}>Socials</h1>
      <div className={isList ? "list-view" : "folders"}>
        <Drag files={files} />
      </div>
    </div>
  );
}

export default Socials;
