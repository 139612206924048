import React from "react";
import MusicButton from "./Buttons/MusicButton";
import isList from "./constants/IsList";
import contrast from "./constants/Contrast";
import Drag from "./Drag";

function UnreleasedMusic() {
  document.title = "Unreleased Music";

  var files = [
    <MusicButton label="Happy Now (Remix).mp3" file="happy now remix.mp3" />,
    <MusicButton label="Some Riddim.mp3" file="VR Riddim.mp3" />,
  ];

  return (
    <div className="page">
      <h1 style={contrast}>Unreleased Music</h1>
      <div className={isList ? "list-view" : "folders"}>
        <Drag files={files} />
      </div>
    </div>
  );
}

export default UnreleasedMusic;
