import React from "react";

function Tutorial() {
  document.title = "Tutorial";

  return (
    <div className="tutorial">
      <div>
        <h1>Tutorial</h1>
        <p>
          Welcome to my personal website! Here's everything you need to know in
          order to have the smoothest browsing experience possible...
        </p>
        <p>
          This website is designed to mimic a typical file explorer. Hence,
          buttons are designed to look like folders and files. To navigate your
          way through the different pages of this website, you will have to
          double-click on the buttons. However, not all files on this website
          will redirect you. There are some files that, when double-clicked on,
          will execute other actions. Try double-clicking on the files yourself
          to see what will happen!
        </p>
        <p>
          This website also comes with a built-in terminal (desktop only). To
          see the different commands that you can perform, type "help".
        </p>
        <p>
          I am also in the process of adding keyboard/mouse shortcuts to this
          website. The entire list of shortcuts is as follows:
          <ul>
            <li>
              <b>Ctrl + C:</b> Copies the pathname of the selected file
            </li>
            <li>
              <b>Ctrl + O:</b> "Opens" the selected file.
            </li>
            <li>
              <b>Right click...</b>
              <ul>
                <li>
                  <b>on file:</b> show file's context menu
                </li>
                <li>
                  <b>on page:</b> show general context menu
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <p>
          There are many other features that this website has, which I will
          leave to you to discover.
        </p>
        <p>Happy browsing!</p>
      </div>
    </div>
  );
}

export default Tutorial;
