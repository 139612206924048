import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ContextMenu from "../ContextMenu";
import { mobile } from "../DetectMobile";
import contrast from "../constants/Contrast";
import highlight from "../constants/FileHighlight";
import isList from "../constants/IsList";
import generateLink from "../hooks/GenerateLink";
import useOutsideClick from "../hooks/OutsideClick";
import useCopyShortcut from "../hooks/UseCopyShortcut";
import useOpenShortcut from "../hooks/UseOpenShortcut";

function FileButton(props) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);

  const wrapperRef = useRef();

  const isMobile = mobile;

  const { label } = props;

  useOutsideClick(wrapperRef, () => {
    setSelected(false);
  });

  useEffect(() => {}, [contrast]);

  const renderMenu = (p) => {
    //console.log("overlay props = ", p);
    return show ? (
      <Tooltip show={show} id="overlay-example" {...p}>
        <ContextMenu {...props} />
      </Tooltip>
    ) : (
      <span></span>
    );
  };

  let i = icon(findIconDefinition({ prefix: "fab", iconName: props.icon }));
  if (i == null) {
    i = icon(findIconDefinition({ prefix: "fas", iconName: props.icon }));
  }

  let fileName = label;

  let extension = "";
  const index = fileName.indexOf(".");
  if (index > -1) {
    fileName = fileName.substring(0, index);
    extension = label.substring(index);
  }
  const path = generateLink(fileName);
  useOpenShortcut(selected, () => window.location.replace(path), path);
  useCopyShortcut(selected, path);

  function handleRightClick(e) {
    e.preventDefault();
    setSelected(true);
    if (e.type === "contextmenu") {
      //console.log(e.type);
      setShow(true);
    } else {
      setShow(false);
    }
  }

  return (
    <div ref={wrapperRef}>
      <div
        className={isList ? "folder-list" : "folder"}
        style={selected ? highlight : {}}
      >
        <Container>
          <OverlayTrigger
            placement={isList ? "bottom-start" : "right"}
            trigger="focus"
            delay={{ show: 200, hide: 10 }}
            overlay={renderMenu}
            onExited={() => setShow(false)}
          >
            <Button
              variant="link"
              size="lg"
              onDoubleClick={!isMobile ? (e) => history.push(path) : null}
              onClick={
                isMobile
                  ? (e) => history.push(path)
                  : (e) => handleRightClick(e)
              }
              onContextMenu={(e) => handleRightClick(e)}
            >
              <FontAwesomeIcon
                icon={i}
                color="black"
                size={isList ? "2x" : "6x"}
                style={contrast}
              />
              {!isList && <br />}
              <span
                className="folder-text"
                style={localStorage.getItem("wallpaper") ? contrast : {}}
              >
                {fileName}
              </span>
              <span
                className="file-extension"
                style={localStorage.getItem("wallpaper") ? contrast : {}}
              >
                {extension}
              </span>
            </Button>
          </OverlayTrigger>
        </Container>
      </div>
    </div>
  );
}

export default FileButton;
