import React, { useState, useEffect } from "react";
import { NavDropdown, Popover, Button, ButtonGroup } from "react-bootstrap";
import useContextMenu from "./hooks/UseContextMenu";

function GeneralContextMenu(props) {
  const { anchorPoint, show } = useContextMenu();
  const [showOptions, setShowOptions] = useState(false);

  function handleChangeView(view) {
    localStorage.setItem("view", view);
    //setshow(false);
    window.location.reload();
  }

  useEffect(() => {
    setShowOptions(false);
  }, [anchorPoint]);

  const viewOptions = (
    <ButtonGroup vertical>
      <Button
        variant="outline-dark"
        disabled={localStorage.getItem("view") === "icons"}
        onClick={() => handleChangeView("icons")}
      >
        Icons
      </Button>
      <Button
        variant="outline-dark"
        disabled={localStorage.getItem("view") === "list"}
        onClick={() => handleChangeView("list")}
      >
        List
      </Button>
    </ButtonGroup>
  );

  const menu = (
    <div className="view-menu" style={{ backgroundColor: "whitesmoke" }}>
      <div style={{ transform: "translateX(120%)" }}>
        <Popover show={!showOptions} style={{ position: "fixed" }}>
          {viewOptions}
        </Popover>
      </div>

      <ButtonGroup vertical>
        <Button variant="outline-dark" onClick={null}>
          <NavDropdown
            title="View"
            menuVariant="dark"
            onMouseEnter={() => setShowOptions(true)}
          />
        </Button>

        <Button variant="outline-dark" onClick={() => window.history.back()}>
          Back
        </Button>
        <Button variant="outline-dark" onClick={() => window.history.forward()}>
          Forward
        </Button>
      </ButtonGroup>
    </div>
  );

  return (
    show && (
      <div className="context-menu">
        <div
          style={{
            position: "fixed",
            padding: "0%",
            left: anchorPoint[0],
            top: anchorPoint[1],
            transform: "translateX(-110%)",
          }}
        >
          {menu}
        </div>
      </div>
    )
  );
}
export default GeneralContextMenu;
